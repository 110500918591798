import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import "./Card.css"
import { useTranslation } from 'react-i18next';

type CruiseCardProps = {
    header_text: string,
    text: string,
    image: IGatsbyImageData,
    size?: number,
    days1: string,
    nights1: string,
    price1: string
    days2: string,
    nights2: string,
    price2: string
};

const CruiseCard: FC <CruiseCardProps> = ({header_text, text, image, size, days1, nights1, price1, days2, nights2, price2}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'cruisePage' });

    return (
        <div className='card_container' style={{ width: size }}>
              <div className='image_container'>
                  <GatsbyImage className='image' alt='' image={image}></GatsbyImage>
                  <div className='image_gradient_bottom'></div>
              </div>

              <div className='text_container  cruise_card_text_container' style={{backgroundColor: '#fff', height: '100%', paddingRight: '25px'}}>
                <p className='text_header'>{header_text}</p>
                <p className='text_content'>{text}</p>
                <hr></hr>
                <div className='column'>
                <div className='row' style={{justifyContent: 'space-evenly', padding: '5px'}}>
                    <div className='row'>
                    <StaticImage
                            src='../../icons/clock.svg'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            className='custom_icon cruise_icon'
                        />
                        <div className='cruise_text'>{t('duration')} {days1} {t('days')} {nights1} {t('nights')}</div>
                    </div>
                    <div className='row'>
                    <StaticImage
                            src='../../icons/money.svg'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            className='custom_icon cruise_icon'
                        />
                        <div className='cruise_text '>{t('from')} €{price1}</div>
                    </div>

                </div>
                <div className='row' style={{justifyContent: 'space-evenly', padding: '5px 5px 10px 5px'}}>
                    <div className='row'>
                    <StaticImage
                            src='../../icons/clock.svg'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            className='custom_icon cruise_icon'
                        />
                        <div className='cruise_text'>{t('duration')} {days2} {t('days')} {nights2} {t('nights')}</div>
                    </div>
                    <div className='row'>
                    <StaticImage
                            src='../../icons/money.svg'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            className='custom_icon cruise_icon'
                        />
                        <div className='cruise_text'>{t('from')} €{price2}</div>
                    </div>

                </div>

                </div>
                
              </div>

        </div>
    );
}

export default CruiseCard;