import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';


import Seo from '../components/Seo/seo';
import { Layout } from '../components/Layout';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import TabPage from '../components/TabPage/TabPageView';
import CruiseTabContent from '../components/CruiseTabContent/CruiseTabContentView';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { graphql, useStaticQuery } from 'gatsby';
import { SpringConfig, config } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import CruiseCard from '../components/Card/CruiseCardView';
import './tour.css'

import { getImageByRelativePath, isBrowser } from '../shared/utilities';

const Carousel = isBrowser() ? require('react-spring-3d-carousel').default : null

type CarouselProps = {
    goToSlide: number,
    offsetRadius: number,
    showNavigation: boolean,
    config: SpringConfig
}

const CruisePage: FC = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'cruisePage' });
    const {width} = useWindowDimensions();
    var offset;
    if (width < 768){
        offset = 2

    }
    else{
        offset = 4
    }

    const [state, setState] = useState<CarouselProps>({
        goToSlide: 0,
        offsetRadius: offset,
        showNavigation: false,
        config: config.gentle
    });


    function getBanner(){
        var banner 
        if (width > 768) {
            banner = <StaticImage
            className={'banner'}
                src="../images/banner3.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
            
    
        }
        else{
            banner = <StaticImage
            className={'banner'}
                src="../images/banner3_mobile.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
                
            />
            // setState({...state,offsetRadius: 2})

        }
        return banner
    }
    const banner = getBanner();

    const data: any = useStaticQuery(graphql`
    {
        Test: file(relativePath: {eq: "cruise1.png"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test2: file(relativePath: {eq: "cruise2.png"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test3: file(relativePath: {eq: "cruise3.png"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test5: file(relativePath: {eq: "cruise4.png"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
       Test8: file(relativePath: {eq: "logo_footer1_bewerkt.png"}) {
        childImageSharp {
          gatsbyImageData(width: 750)
        }
        relativePath
      }
        Test9: file(relativePath: {eq: "logo_footer2_bewerkt.png"}) {
        childImageSharp {
          gatsbyImageData(width: 750)
        }
        relativePath
      }
        Test10: file(relativePath: {eq: "logo_footer3_bewerkt.png"}) {
        childImageSharp {
          gatsbyImageData(width: 750)
        }
        relativePath
      }
        Test11: file(relativePath: {eq: "logo_footer4_bewerkt.png"}) {
        childImageSharp {
          gatsbyImageData(width: 750)
        }
        relativePath
      }
     
     }
    `);

    let slides = [
        {
            key: uuidv4(),
            content: <CruiseCard text={t("cruise1")} size={700} image={getImageByRelativePath("cruise1.png", data)} header_text={t('cruise1_title')} price1='3400' price2='4000' days1='5' days2='6' nights1='4' nights2='5' />
        },
        {
            key: uuidv4(),
            content: <CruiseCard text={t("cruise2")} size={700} image={getImageByRelativePath("cruise2.png", data)} header_text={t('cruise2_title')} price1='2100' price2='2800' days1='5' days2='8' nights1='4' nights2='7' />
        },
        {
            key: uuidv4(),
            content: <CruiseCard text={t("cruise3")} size={700} image={getImageByRelativePath("cruise3.png", data)} header_text={t('cruise3_title')} price1='2600' price2='3600' days1='5' days2='7' nights1='4' nights2='6' />
        },
        {
            key: uuidv4(),
            content: <CruiseCard text={t("cruise4")} size={700} image={getImageByRelativePath("cruise4.png", data)} header_text={t('cruise4_title')} price1='5400' price2='5400' days1='8' days2='8' nights1='7' nights2='7' />
        },
        {
            key: uuidv4(),
            content: <CruiseCard text={t("cruise1")} size={700} image={getImageByRelativePath("cruise1.png", data)} header_text={t('cruise1_title')} price1='3200' price2='3800' days1='5' days2='6' nights1='4' nights2='5' />
        },
        {
            key: uuidv4(),
            content: <CruiseCard text={t("cruise2")} size={700} image={getImageByRelativePath("cruise2.png", data)} header_text={t('cruise2_title')} price1='2100' price2='2800' days1='5' days2='8' nights1='4' nights2='7' />
        },
        {
            key: uuidv4(),
            content: <CruiseCard text={t("cruise3")} size={700} image={getImageByRelativePath("cruise3.png", data)} header_text={t('cruise3_title')} price1='2600' price2='3600' days1='5' days2='7' nights1='4' nights2='6' />
        },
        {
            key: uuidv4(),
            content: <CruiseCard text={t("cruise4")} size={700} image={getImageByRelativePath("cruise4.png", data)} header_text={t('cruise4_title')} price1='5400' price2='5400' days1='8' days2='8' nights1='7' nights2='7' />
        },

    ].map((slide, index) => {
        return { ...slide, onClick: () => setState({ ...state, goToSlide: index }) };
    });

    return (
        <Layout>
            <Seo title={'cruise'} description={"Wij zoeken een cruise die bij u past."} />

            {banner}

            <div style={{
                    textAlign: 'center',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1.5rem 0 1.5rem 0',
                    boxShadow: '0 0 10px rgb(72 72 72 / 16%)',
                    zIndex: '200',
                    backgroundColor: '#ffffff'
                }}>
                    <StaticImage
                    className='palmleaf'
                        style={{
                            position: 'absolute',
                            zIndex: '1', width: '23rem',
                            right: '0%',
                            bottom: '0',
                            opacity: '0.1',
                            transform: 'scaleX(-1)'
                        }}
                        src='../images/Palmleaf.png'
                        quality={95}
                        formats={['auto', 'webp', 'avif']}
                        loading='eager'
                        alt='Intro banner'
                    />

                    <div className='main_text' >
                        {t("cruiseIntro1")}       
                        <p></p>
                        <div>{t("cruiseIntro2")}</div>
                        <p></p>
                        <div>{t("cruiseIntro3")}</div>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                        <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/contact/'>{t("cruiseIntro_button")}</Link>
                        </div>
                    </div>
                    </div>
                    
                </div>

                <div className='cruise_carousel_container' style={{ width: '80%', height: '100vh', margin: '0 auto', paddingTop: '100px' }}>
                {isBrowser() ? <Carousel
                    slides={slides}
                    goToSlide={state.goToSlide}
                    offsetRadius={state.offsetRadius}
                    showNavigation={state.showNavigation}
                    animationConfig={state.config}
                /> : null}


                <div className='row slider_controller' style={{margin: '-10rem auto 0px'}}>
                    <div onClick={() =>setState({ ...state, goToSlide: (state.goToSlide-1) })}>
                        <StaticImage
                        className='arrow'
                            src='../icons/arrow_backward.png'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                        />
                    </div>


                    <div onClick={() =>setState({ ...state, goToSlide: (state.goToSlide+1) })}>
                        <StaticImage
                        className='arrow'
                            src='../icons/arrow_forward.png'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                        />
                    </div>


                </div>


            </div>


            {/* <div>
                <TabPage tabNames={['Cruise']} tabContent={[<CruiseTabContent excludedList={[t("excluded1"),t("excluded2"),t("excluded3"),t("excluded4"),t("excluded4"),t("excluded6"),t("excluded7"),]} includedList={[t("included1"),t("included2"),t("included3"),t("included4"),t("included5"),t("included6"),t("included7"),t("included8"),]} mainHeader={'Cruise'}></CruiseTabContent>]}/>
            </div> */}
                        <div style={{height: '200px'}}></div>

                        <div className='logo_row'
                style={{
                    width: '100%',
                    display: `flex`,
                    alignItems: `center`,
                    justifyContent: `space-around`,
                    marginTop: '20px'
                }}
            >
                    <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={getImageByRelativePath("logo_footer1_bewerkt.png", data)}></GatsbyImage>
                    <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={getImageByRelativePath("logo_footer2_bewerkt.png", data)}></GatsbyImage>
                    <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={getImageByRelativePath("logo_footer3_bewerkt.png", data)}></GatsbyImage>
                    <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={getImageByRelativePath("logo_footer4_bewerkt.png", data)}></GatsbyImage>


            </div>


        </Layout>
    )
}

export default CruisePage;